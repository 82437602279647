<template>
    <div>
        <Dialog ref="dlg" :iconCls="icon" bodyCls="f-column" :title="title" :draggable="true" :modal="true" closed :dialogStyle="{width:'750px', height:'550px'}">
            <div class="f-full" style="overflow:auto">
                <Form ref="form" :model="model" :rules="rules" @validate="errors=$event">
                    <fieldset class="c-fieldset">
                        <legend class="c-legend"><b>{{ titlePanel }}</b></legend>
                        <table width="100%" cellspacing="1" cellpadding="5" border="0">
                            <tbody>
                                <tr>
                                    <td>TR ID</td>
                                    <td style="width:35%;">
                                        <NumberBox inputId="trucking_order_no" name="trucking_order_no" class="f-field-text" v-model="modelNotMapping.trucking_order_no" :disabled="disableData" :spinners="false"></NumberBox>
                                    </td>
                                    <td>Request By</td>
                                    <td>
                                        <TextBox inputId="created_by" name="created_by" class="f-field-text" v-model="modelNotMapping.created_by" :disabled="disableData"></TextBox>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Manifest ID</td>
                                    <td>
                                        <NumberBox inputId="manifest_id" name="manifest_id" class="f-field-text" v-model="modelNotMapping.manifest_id" :disabled="disableData" :spinners="false"></NumberBox>
                                    </td>
                                    <td>Request Time</td>
                                    <td>
                                        <TextBox inputId="created_date" name="created_date" class="f-field-text" v-model="modelNotMapping.created_date" :disabled="disableData"></TextBox>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Client ID <font color="red">*</font>
                                    </td>
                                    <td> 
                                        <ComboBox  :data="clients" inputId="client_id" name="client_id" @filterChange="optionClient($event)" class="f-field-text" v-model="model.client_id" :disabled="disableData">
                                            <template slot="item" slot-scope="scope">
                                                <span style="font-weight:bold">{{scope.row.text}}</span><br/>
                                                <span style="color:#888">{{scope.row.address}}</span><br/>
                                            </template>
                                        </ComboBox>
                                        <div class="error">{{textError.client_id}}</div>
                                    </td>
                                    <td>Schedule Date <font color="red">*</font></td>
                                    <td>
                                        <DateBox inputId="schedule_date" name="schedule_date" class="f-field-text" v-model="model.schedule_date" format="dd/MM/yyyy" :disabled="disableData"></DateBox>
                                        <div class="error">{{textError.schedule_date}}</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Transport Mode <font color="red">*</font></td>
                                    <td>
                                        <ComboBox  :data="transportModes" inputId="transport_mode" name="transport_mode" class="f-field-text" v-model="model.transport_mode" :disabled="disableData"></ComboBox>
                                        <div class="error">{{textError.transport_mode}}</div>
                                    </td>
                                    <td>Preferred Vehicle Type <font color="red">*</font></td>
                                    <td>
                                        <ComboBox  :data="vehicleTypes" inputId="pref_vehicle_type" name="pref_vehicle_type" @filterChange="optionVehicleType($event)" class="f-field-text" v-model="model.pref_vehicle_type" :disabled="disableData">
                                            <template slot="item" slot-scope="scope">
                                                <span style="font-weight:bold">{{scope.row.text}}</span><br/>
                                                <span style="color:#888">Weight : {{scope.row.weight_cap}}  Volume : {{scope.row.volume_cap}}</span><br/>
                                            </template>
                                        </ComboBox>
                                        <div class="error">{{textError.pref_vehicle_type}}</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Budget</td>
                                    <td>
                                        <NumberBox inputId="budget" name="budget" class="f-field-text" v-model="model.budget" :spinners="false" :disabled="disableData"></NumberBox>
                                    </td>
                                    <td>TR Status</td>
                                    <td>
                                        <TextBox inputId="tr_status_desc" name="tr_status_desc" class="f-field-text" v-model="modelNotMapping.tr_status_desc" :disabled="true"></TextBox>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </fieldset>
                    <fieldset class="c-fieldset">
                        <legend class="c-legend"><b>Details Information</b></legend>
                        <Tabs style="height:205px">
                            <TabPanel :title="'Trucking Option'">
                                <DataGrid
                                    ref="dataGridVehicle"
                                    :columnResizing="true"
                                    :border="false"                                    
                                    :data="dataGridVehicle.rows"
                                    :selectionMode="dataGridVehicle.selectionMode"
                                    style="width:676px;height:65px"
                                    >
                                    <GridColumnGroup :frozen="true" align="left" width="370">
                                        <GridHeaderRow>
                                            <GridColumn field="vehicle_desc" title="Vehicle Id" :frozen="true" width="90"></GridColumn>
                                            <GridColumn field="driver_desc" title="Driver" :frozen="true" width="140"></GridColumn>
                                            <GridColumn field="co_driver_desc" title="Co Driver" :frozen="true" width="140"></GridColumn>
                                        </GridHeaderRow>
                                    </GridColumnGroup>
                                    <GridColumnGroup>
                                        <GridHeaderRow>
                                            <GridColumn field="transporter_desc" title="Transporter Id" width="130"></GridColumn>
                                            <GridColumn field="status_desc" title="Status" width="100"></GridColumn>
                                            <GridColumn field="type_desc" title="Type" width="100"></GridColumn>
                                            <GridColumn field="max_volume" title="Max Volume" width="100"></GridColumn>
                                            <GridColumn field="max_weight" title="Max Weight" width="100"></GridColumn>
                                        </GridHeaderRow>
                                    </GridColumnGroup>                            
                                </DataGrid>
                            </TabPanel>
                            <TabPanel :title="'Order Summary'">
                                <table width="100%" cellspacing="1" cellpadding="5" border="0">
                                    <tbody>
                                        <tr>
                                            <td>Origin ID <font color="red">*</font></td>
                                            <td>
                                                <ComboBox  :data="customer_origins" inputId="origin_id" name="origin_id" @filterChange="optionCustomerOrigin($event)" @selectionChange="onSelectionCustomerOrigin($event)" class="f-field-text" v-model="model.origin_id" :disabled="disableData">
                                                    <template slot="item" slot-scope="scope">
                                                        <span style="font-weight:bold">{{scope.row.text}}</span><br/>
                                                        <span style="color:#888">{{scope.row.name}}</span><br/>
                                                        <span style="color:#888">{{scope.row.address}}</span><br/>
                                                    </template>
                                                </ComboBox>
                                                <div class="error">{{textError.origin_id}}</div>
                                            </td>
                                            <td>Destination ID <font color="red">*</font></td>
                                            <td>
                                                <ComboBox  :data="customer_destinations" inputId="dest_id" name="dest_id" @filterChange="optionCustomerDestination($event)" @selectionChange="onSelectionCustomerDestination($event)" class="f-field-text" v-model="model.dest_id" :disabled="disableData">
                                                    <template slot="item" slot-scope="scope">
                                                        <span style="font-weight:bold">{{scope.row.text}}</span><br/>
                                                        <span style="color:#888">{{scope.row.name}}</span><br/>
                                                        <span style="color:#888">{{scope.row.address}}</span><br/>
                                                    </template>
                                                </ComboBox>
                                                <div class="error">{{textError.dest_id}}</div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Origin Name</td>
                                            <td>
                                                <TextBox inputId="origin_customer_name" name="origin_customer_name" class="f-field-text" v-model="modelNotMapping.origin_customer_name" :disabled="true"></TextBox>
                                            </td>
                                            <td>Destination Name</td>
                                            <td>
                                                <TextBox inputId="dest_customer_name" name="dest_customer_name" class="f-field-text" v-model="modelNotMapping.dest_customer_name" :disabled="true"></TextBox>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Origin Address</td>
                                            <td>
                                                <TextBox inputId="origin_customer_address" name="origin_customer_address" class="f-field-text" v-model="modelNotMapping.origin_customer_address" :disabled="true"></TextBox>
                                            </td>
                                            <td>Destination Address</td>
                                            <td>
                                                <TextBox inputId="dest_customer_address" name="dest_customer_address" class="f-field-text" v-model="modelNotMapping.dest_customer_address" :disabled="true"></TextBox>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Origin Area <font color="red">*</font></td>
                                            <td>
                                                <ComboBox  :data="origins" inputId="origin_area_id" name="origin_area_id" @filterChange="optionOrigin($event)" class="f-field-text" v-model="model.origin_area_id" :disabled="disableData"></ComboBox>
                                                <div class="error">{{textError.origin_area_id}}</div>
                                            </td>
                                            <td>Dest Area <font color="red">*</font></td>
                                            <td>
                                                <ComboBox  :data="destinations" inputId="dest_area_id" name="dest_area_id" @filterChange="optionDestination($event)" class="f-field-text" v-model="model.dest_area_id" :disabled="disableData"></ComboBox>
                                                <div class="error">{{textError.dest_area_id}}</div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Req. Pickup Time <font color="red">*</font></td>
                                            <td>
                                                <DateBox inputId="req_pickup_time" name="req_pickup_time" class="f-field-text" v-model="model.req_pickup_time" format="dd/MM/yyyy" :disabled="disableData"></DateBox>
                                                <div class="error">{{textError.req_pickup_time}}</div>
                                            </td>
                                            <td>Req. Arrival Time <font color="red">*</font></td>
                                            <td>
                                                <DateBox inputId="req_arrival_time" name="req_arrival_time" class="f-field-text" v-model="model.req_arrival_time" format="dd/MM/yyyy" :disabled="disableData"></DateBox>
                                                <div class="error">{{textError.req_arrival_time}}</div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </TabPanel>
                            <TabPanel :title="'Log'">
                                <table width="100%" cellspacing="1" cellpadding="5" border="0">
                                    <tbody>
                                        <tr>
                                            <td>
                                                Updated By
                                            </td>
                                            <td>
                                                <TextBox inputId="updated_by" name="updated_by" class="f-field-text" :value="modelNotMapping.updated_by" :disabled="true"></TextBox>
                                            </td>
                                            <td>
                                                Updated Date
                                            </td>
                                            <td>
                                                <TextBox inputId="updated_date" name="updated_date" class="f-field-text" :value="modelNotMapping.updated_date" :disabled="true"></TextBox>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </TabPanel>
                        </Tabs>
                    </fieldset>
                </Form>
            </div>
            <div class="dialog-button">
                <LinkButton :iconCls="iconUtil.iconCancel" @click="$refs.dlg.close()" style="width:89px">{{ btnText.close }}</LinkButton>
            </div>
        </Dialog>
    </div>
</template>
<script>
    import TruckingOrder from '@/models/TruckingOrder';
    import AccessUtil from '@/utils/AccessUtil';
    import TransportService from '@/services/TransportService';
    import ModelUtil from '@/utils/ModelUtil';
    import InformationUtil from '@/utils/InformationUtil';
    import IconUtil from '@/utils/IconUtil';
    import OptionService from '@/services/OptionService';
    import GlobalUtil from '@/utils/GlobalUtil';

    export default {
        name : "FormTruckingOrderComponent",
        props : {
            actionData : {
                type : String,
                default : ''
            }
        },
        data(){
            return {
                title : InformationUtil.editText + " " + InformationUtil.truckingOrder,
                titlePanel : InformationUtil.truckingOrder,
                titleVehicle : InformationUtil.editText + " " + InformationUtil.vehicle,

                iconUtil : IconUtil,
                icon : IconUtil.iconEdit,
                model : TruckingOrder,
                action : {
                    view : false,
                    add : false,
                    edit : false,
                    delete : false
                },
                btnText :{
                    refresh : InformationUtil.refreshText,
                    add : InformationUtil.addText,
                    edit : InformationUtil.editText,
                    delete : InformationUtil.removeText,
                    save : InformationUtil.saveText,
                    cancel : InformationUtil.cancelText,
                    search : InformationUtil.searchText,
                    close : InformationUtil.closeText
                },
                
                disableManifestConfirm : false,
                disableManifestSelfBilling : false,
                disableData : true,
                modelNotMapping : {
                    trucking_order_no : 0,
                    manifest_id : 0,
                    manifest_status : 0,
                    created_by : "",
                    updated_by : "",
                    created_date : "",
                    updated_date : "",
                    tr_status_desc : "",
                    origin_customer_name : "",
                    dest_customer_name : "",
                    origin_customer_address : "",
                    dest_customer_address : "",
                    invpch_hash_id : null,
                },

                dataGridVehicle : {
                    loading: false,
                    rows : [],
                    total: 0,
                    start: 0,
                    pageNumber: 1,
                    pageSize: 5,
                    selectionMode : 'single',

                    // filter
                    search : "",
                    sortField : "",
                    sortOrder : "",
                    id : null
                },

                // validation
                rules: {
                    client_id : 'required',
                    transport_mode : 'required',
                    schedule_date : 'required',
                    pref_vehicle_type : 'required',
                    origin_id : 'required',
                    dest_id : 'required',
                    origin_area_id : 'required',
                    dest_area_id : 'required',
                    req_pickup_time : 'required',
                    req_arrival_time : 'required',
                },
                errors: {},
                textError :{
                    client_id : '',
                    transport_mode : '',
                    schedule_date : '',
                    pref_vehicle_type : '',
                    origin_id : '',
                    dest_id : '',
                    origin_area_id : '',
                    dest_area_id : '',
                    req_pickup_time : '',
                    req_arrival_time : '',
                },

                // combobox
                transportModes: [
                    { value :1, text :"LAND"},
                    { value :2, text :"AIR"},
                    { value :3, text :"SEA"},
                    { value :4, text :"RAILWAY"},
                ],
                clients : [],
                vehicleTypes : [],
                customer_origins : [],
                customer_destinations : [],
                origins : [],
                destinations : [],

                drivers : [],
                coDriver : [],
                vehicles : [],
            }
            
        },
        methods : {
            onPageShow(id){
                this.action = AccessUtil.getAccessAction(this.$props.actionData);
                if(this.action.view){
                    if(id != null && id != undefined){
                        TransportService.get('/truckingOrder/' + id)
                            .then((response) => {
                                let dataResponse = response;
                                if(dataResponse.data !== "" && dataResponse.data !== null){
                                    // additional for combo box
                                    this.clients = [{value: dataResponse.data.client_id, text: dataResponse.data.client_desc, address: dataResponse.data.client_address}];
                                    this.vehicleTypes = [{value: dataResponse.data.pref_vehicle_type, text: dataResponse.data.pref_vehicle_type_desc}]
                                    this.customer_origins = [{value: dataResponse.data.origin_id, 
                                                            text: dataResponse.data.origin_customer_desc, 
                                                            name : dataResponse.data.origin_customer_name, 
                                                            address: dataResponse.data.origin_customer_address,
                                                            area_id : dataResponse.data.origin_area_id,
                                                            area_desc : dataResponse.data.origin_area_desc,
                                                            area_description : dataResponse.data.origin_area_description
                                                        }]
                                    this.customer_destinations = [{value: dataResponse.data.dest_id, 
                                                                text: dataResponse.data.dest_customer_desc, 
                                                                name : dataResponse.data.dest_customer_name, 
                                                                address: dataResponse.data.dest_customer_address,
                                                                area_id : dataResponse.data.dest_area_id,
                                                                area_desc : dataResponse.data.dest_area_desc,
                                                                area_description : dataResponse.data.dest_area_description
                                                            }]
                                    this.origins = [{value: dataResponse.data.origin_area_id, text: dataResponse.data.origin_area_desc +" - "+dataResponse.data.origin_area_description}]
                                    this.destinations = [{value: dataResponse.data.dest_area_id, text: dataResponse.data.dest_area_desc +" - "+dataResponse.data.dest_area_description}]

                                    this.model = ModelUtil.resetModel(this.model);
                                    this.model = ModelUtil.mapModel(this.model, dataResponse.data)
                                    this.modelNotMapping = ModelUtil.resetModel(this.modelNotMapping);
                                    this.modelNotMapping = ModelUtil.mapModel(this.modelNotMapping, dataResponse.data)

                                    // mapping data
                                    this.modelNotMapping.trucking_order_no = parseInt(this.modelNotMapping.trucking_order_no);
                                    this.modelNotMapping.manifest_id = parseInt(this.modelNotMapping.manifest_id);
                                    this.model.budget = parseInt(this.model.budget);

                                    this.model.schedule_date = new Date(this.model.schedule_date);
                                    this.model.req_pickup_time = new Date(this.model.req_pickup_time);
                                    this.model.req_arrival_time = new Date(this.model.req_arrival_time);

                                    // disable field when manifest confirm
                                    this.disableManifestConfirm = this.modelNotMapping.manifest_status > 0 ? true : false;
                                    this.disableManifestSelfBilling = this.modelNotMapping.invpch_hash_id != null && this.modelNotMapping.invpch_hash_id != '' ? true : false;

                                    this.onLoadVehicle();

                                    this.$refs.dlg.open();
                                }
                            });
                    }
                }
            },

            onLoadVehicle(){
                this.dataGridVehicle.id = this.modelNotMapping.manifest_id;
                return TransportService.post('/manifest/showVehicle', this.dataGridVehicle)
                    .then((response) => {
                        let dataResponse = response;
                        this.dataGridVehicle.loading = false;
                        if(dataResponse.data !== "" && dataResponse.data !== null){
                            if(dataResponse.code == 200){
                                let data = dataResponse.data;
                                this.dataGridVehicle.total = data.total;
                                this.dataGridVehicle.rows = data.rows;
                                
                                if(data.rows.length > 0){
                                    this.modelVehicle = ModelUtil.resetModel(this.modelVehicle);
                                    this.modelVehicle = ModelUtil.mapModel(this.modelVehicle, data.rows[0]);
                                }

                            }else{
                                this.$messager.alert({
                                    title: "Error",
                                    icon: "error",
                                    msg: dataResponse.message
                                });
                            }
                        }
                    });
            },

            // combo box data
            async optionClient(event){
                let search = event == null ? "" : event.filterValue;
                return await OptionService.getOption("/client", search)
                    .then((response) => {
                        this.clients = response.data;
                    }).catch((error) => {
                        this.$refs.notification.error(error);
                    });
            },
            async optionCustomerOrigin(event){
                let search = event == null ? "" : event.filterValue;
                return await OptionService.getOption("/customer", search)
                    .then((response) => {
                        this.customer_origins = response.data;
                    }).catch((error) => {
                        this.$refs.notification.error(error);
                    });
            },
            async optionCustomerDestination(event){
                let search = event == null ? "" : event.filterValue;
                return await OptionService.getOption("/customer", search)
                    .then((response) => {
                        this.customer_destinations = response.data;
                    }).catch((error) => {
                        this.$refs.notification.error(error);
                    });
            },
            async optionOrigin(event){
                let search = event == null ? "" : event.filterValue;
                return await OptionService.getOption("/area", search)
                    .then((response) => {
                        this.origins = response.data;
                    }).catch((error) => {
                        this.$refs.notification.error(error);
                    });
            },
            async optionDestination(event){
                let search = event == null ? "" : event.filterValue;
                return await OptionService.getOption("/area", search)
                    .then((response) => {
                        this.destinations = response.data;
                    }).catch((error) => {
                        this.$refs.notification.error(error);
                    });
            },
            async optionVehicleType(event){
                let search = event == null ? "" : event.filterValue;
                return await OptionService.getOption("/vehicleType", search)
                    .then((response) => {
                        this.vehicleTypes = response.data;
                    }).catch((error) => {
                        this.$refs.notification.error(error);
                    });
            },
            async optionDriver(event){
                let search = event == null ? "" : event.filterValue;
                return await OptionService.getOption("/driver", search)
                    .then((response) => {
                        this.drivers = response.data;
                    }).catch((error) => {
                        this.$refs.notification.error(error);
                    });
            },
            async optionCoDriver(event){
                let search = event == null ? "" : event.filterValue;
                return await OptionService.getOption("/driver", search)
                    .then((response) => {
                        this.coDriver = response.data;
                    }).catch((error) => {
                        this.$refs.notification.error(error);
                    });
            },
            async optionVehicle(event){
                let search = event == null ? "" : event.filterValue;
                return await OptionService.getOption("/vehicle", search)
                    .then((response) => {
                        this.vehicles = response.data;
                    }).catch((error) => {
                        this.$refs.notification.error(error);
                    });
            },
            onCallAllOptionVehicle(){
                this.optionDriver();
                this.optionCoDriver();
                this.optionVehicle();
            },
            onCallAllOption(){
                this.optionClient();
                this.optionCustomerOrigin();
                this.optionCustomerDestination();
                this.optionOrigin();
                this.optionDestination();
                this.optionVehicleType();
            },
            onSelectionCustomerOrigin(row){
                if(row != null){
                    this.modelNotMapping.origin_customer_name = row.name;
                    this.modelNotMapping.origin_customer_address = row.address;
                    this.origins = [{value: row.area_id, text: row.area_desc +" - "+row.area_description}]
                    this.model.origin_area_id = row.area_id;
                }
            },
            onSelectionCustomerDestination(row){
                if(row != null){
                    this.modelNotMapping.dest_customer_name = row.name;
                    this.modelNotMapping.dest_customer_address = row.address;
                    this.destinations = [{value: row.area_id, text: row.area_desc +" - "+row.area_description}]
                    this.model.dest_area_id = row.area_id;
                }
            },
        },
        created(){
            GlobalUtil.removeWindow();
        }
    }
</script>