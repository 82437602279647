<template>
    <div class="main-app">
      <Layout>
            <LayoutPanel region="north" :split="true" :border="false" class="fill" style="height: 101px">
                <div class="header-view">
                    <div style="float:left; padding:0px; margin-top:5px;">
                        <img src="../assets/images/icon_logo.png" style="padding:0; margin:0px 10px; height: auto;" width="83"/>
                    </div>
                    <div style="float:left; line-height:3px; margin-top:0px; padding:2px 5px;">
                        <h1 style="text-shadow: 0px 0px 2px #c0c0c0; font-size: 22px; font-weight: bold; margin-bottom: 2px; padding-top: 5px;">ERUNIX TECHNOLOGY</h1>
                        <p style="padding-bottom: 0px;">
                            <b>Transport Management System</b>
                        </p>
                        <p style="padding-bottom: 0px;">Jl Cempaka 2 RT.003/RW.002 Jatibening</p>
                        <p style="padding-bottom: 0px;">Pondok Gede, Bekasi, West Java 17412 Indonesia</p>
                    </div>
                </div>
            </LayoutPanel>

            <!-- Navigation -->
            <LayoutPanel region="west"  :split="true" :collapsible="true" :collapsed="true" :expander="true" title="Navigation" class="fill main-menu" :headerStyle="{backgroundColor: '#2d89ef', }" :bodyStyle="{padding:'5px'}" :panelStyle="{width:'200px',minWidth:'160px'}" >
                <Tree ref="treeRef" :animate="true" :lines="true" :data="menuData" @selectionChange="onMenuClick($event)"></Tree>
            </LayoutPanel>
            
            <!-- main content -->
            <LayoutPanel region="center" :split="true" title="Main Content" :panelStyle="{height:'100%'}" :headerStyle="{backgroundColor: '#2d89ef', }" class="main-panel">
                <Tabs ref="panelRef" :scrollable="true" style="height:100%" @tabClose="onCloseTab($event)" @tabSelect="onSelectedTab($event)">
                    <TabPanel v-for="(tab) in panels" :key="tab.title" :title="tab.title" :closable="tab.closable">
                        <component :is="tab.component"></component>
                    </TabPanel>
                </Tabs>
            </LayoutPanel>

            <!-- footer -->
            <LayoutPanel region="south" :split="true" class="fill" style="height: 30px;">
                <div style="padding : 5px;">
                    Copyright &copy; {{ currentYear }}, All right reserved.
                    <div style="float:right;" id="log_as">&nbsp;|&nbsp;Role : <b>{{roleName}}</b></div>
                    <div style="float:right;">Username is <b>{{ username }}</b></div>
                </div> 
            </LayoutPanel>
      </Layout>
    </div>
  </template>
   
<script>
    // import all component for reference
    import InformationUtil from '@/utils/InformationUtil';
    import { mapGetters } from 'vuex';
    import DashboardView from './DashboardView.vue';

    // transport
    import TruckingOrderView from './transport/TruckingOrderView.vue';
    import TrafficMonitoringView from './transport/TrafficMonitoringView.vue';

    // user management
    import UserProfileView from './usermanagement/UserProfileView.vue';

    import AccessUtil from '@/utils/AccessUtil';
    import GlobalUtil from '@/utils/GlobalUtil';

    export default {
        name: 'AppMainView',
        components: {
            DashboardView,

            // transport
            TruckingOrderView,
            TrafficMonitoringView,

            // user management
            UserProfileView,

        },
        data() {
            return {
                menuData: [],
                //tabs
                panels: [],
                username: "",
                roleName: "",
                currentYear : new Date().getFullYear(),
            }
        },
        async mounted(){
            // build dashboard tab
            this.onBuildDashboard();
            await this.$store.dispatch('getMenuAccess');
            await this.$store.dispatch('getUser');
            this.menuData = this.$store.getters.menu;
            this.username = this.$store.getters.user.email;
            this.roleName = "Vendor";
        },
        computed: {
            ...mapGetters({ 
                menu: 'menu',
                menuAccess: 'menuAccess'
            })
        },
        methods:{
            async onSelectedTab($event){
                if($event.$children !== undefined && $event.$children.length > 0) {
                    if (typeof $event.$children[0].onTabSelected === "function") { 
                        $event.$children[0].onTabSelected();
                    }
                }
            },
            async onCloseTab(event){
                if (event) {
                    for(let i = 0; i < this.panels.length; i++){
                        if(this.panels[i].title == event.title){
                            await this.panels.splice(i, 1);
                            break;
                        }
                    }
                }
            },
            onLogout() {
                this.$store.dispatch("logout").then(() => {
                    this.$router.push({ name: "login" });
                });
            },
            onMenuClick(item) {
                // handling logout
                if(item.id == 'logout') {
                    // set notification
                    this.$messager.confirm({
                        title: 'Confirm',
                        msg: InformationUtil.confirmLogout,
                        result: (r) => {
                            if (r){
                                this.onLogout();
                                return;
                            }
                        }
                    })
                    return;
                }

                // handling menu access
                if( ! AccessUtil.getAccessActionByType(item.id, 'view') ) {
                    this.$messager.alert({
                        title: 'Warning',
                        msg: InformationUtil.noAccess,
                        icon: 'warning'
                    })
                    return;
                }

                if(item.children !== undefined && item.children.length > 0) {
                    item.state = "open";
                    return;
                }

                if(this.panels.length == 0){

                    this.panels.push({
                        title: item.text,
                        content: item.text,
                        closable : true,
                        component: item.component,
                    });
                    this.$refs.panelRef.select(this.panels.length - 1);

                }else{
                    var isExist = false;
                    var indexPanel = 0;
                    for(var i=0; i<this.panels.length; i++){
                        if(this.panels[i].title == item.text){
                            isExist = true;
                            indexPanel = i;
                            break;
                        }
                    }
                    if(!isExist){
                        this.panels.push({
                            title: item.text,
                            content: item.text,
                            closable : true,
                            component: item.component,
                        });
                        this.$nextTick(() => {
                            this.$refs.panelRef.select(this.panels.length - 1);
                        });
                    } else {
                        this.$nextTick(() => {
                            this.$refs.panelRef.select(indexPanel);
                        });
                    }
                }
            },
            onBuildDashboard(){
                this.panels.push({
                    title: "Dashboard",
                    content: "Dashboard",
                    closable : false,
                    component : "DashboardView"
                });
                this.$nextTick(() => {
                    this.$refs.panelRef.select(this.panels.length - 1);
                });
            },
        },
        created(){
            GlobalUtil.removeWindow();
        }
    };
</script>

<style>
    .panel-tool{
        background-color: #fff !important;
    }
</style>