<template>
    <div>
        <Dialog ref="dlg" :iconCls="icon" bodyCls="f-column" :title="title" :draggable="true" :modal="true" closed :dialogStyle="{width:'750px', height:'600px'}">
            <div class="f-full" style="overflow:auto">
                <Panel>
                    <MenuButton text="Process" :plain="true">
                        <Menu @itemClick="onClickMenu($event)">
                            <MenuItem value="onViewTruckingOrder" text="Trucking Order" :disabled="menuAction.truckingOrder"></MenuItem>
                            <MenuSep></MenuSep>
                            <MenuItem value="onPrint" text="Print Manifest" :iconCls="iconUtil.iconPrint" :disabled="menuAction.printManifest"></MenuItem>
                        </Menu>
                    </MenuButton>
                    <MenuButton text="Maps" :plain="true">
                        <Menu @itemClick="onClickMenu($event)" >
                            <MenuItem value="onViewTracking"  text="View On Vehicle Tracking"></MenuItem>
                        </Menu>
                    </MenuButton>
                    <div v-if="disableManifestConfirm" class="manifest-confirm">
                        [ CONFIRMED ]
                    </div>
                </Panel>
                <Form ref="form" :model="model" :rules="rules" @validate="errors=$event">
                    <fieldset class="c-fieldset">
                        <legend class="c-legend"><b>Manifest</b></legend>
                        <table width="100%" cellspacing="1" cellpadding="5" border="0">
                            <tbody>
                                <tr>
                                    <td>Manifest ID</td>
                                    <td>
                                        <TextBox class="f-field-text" v-model="modelNotMapping.manifest_number" :disabled="disableData"></TextBox>
                                    </td>
                                    <td>Start</td>
                                    <td>
                                        <NumberBox inputId="start" name="start" class="f-field-text" v-model="model.start" :spinners="false" :disabled="disableData">
                                            <Addon>
                                                <span class="text-inner-box">KM</span>
                                            </Addon>
                                        </NumberBox>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Schedule Date</td>
                                    <td>
                                        <TextBox class="f-field-text" v-model="modelNotMapping.schedule_date_desc" :disabled="disableData"></TextBox>
                                    </td>
                                    <td>Finish</td>
                                    <td>
                                        <NumberBox inputId="finish" name="finish" class="f-field-text" v-model="model.finish" :spinners="false" :disabled="disableData">
                                            <Addon>
                                                <span class="text-inner-box">KM</span>
                                            </Addon>
                                        </NumberBox>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Trip</td>
                                    <td>
                                        <TextBox class="f-field-text" v-model="modelNotMapping.trip_no" :disabled="disableData"></TextBox>
                                    </td>
                                    <td>Mileage</td>
                                    <td>
                                        <TextBox class="f-field-text" v-model="modelNotMapping.mileage" :disabled="disableData" align="right">
                                            <Addon>
                                                <span class="text-inner-box">KM</span>
                                            </Addon>
                                        </TextBox>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Trucking Order ID</td>
                                    <td>
                                        <TextBox class="f-field-text" v-model="modelNotMapping.trucking_order_number" :disabled="disableData"></TextBox>
                                    </td>
                                    <td>Mode <font color="red">*</font></td>
                                    <td>
                                        <ComboBox  :data="transportModes" inputId="mode" name="mode" class="f-field-text" v-model="model.mode" :disabled="disableData"></ComboBox>
                                        <div class="error">{{textError.mode}}</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Order Case <font color="red">*</font></td>
                                    <td>
                                        <ComboBox  :data="orderCases" inputId="order_case" name="order_case" class="f-field-text" v-model="model.order_case" :disabled="disableData"></ComboBox>
                                        <div class="error">{{textError.order_case}}</div>
                                    </td>
                                    <td>Approved By</td>
                                    <td>
                                        <TextBox inputId="approved_by" name="approved_by" class="f-field-text" v-model="model.approved_by" :disabled="disableData"></TextBox>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </fieldset>
                    <fieldset class="c-fieldset">
                        <legend class="c-legend"><b>Vehicle</b></legend>
                        <DataGrid
                                ref="dataGridVehicle"
                                :columnResizing="true"
                                :border="false"                                    
                                :data="dataGridVehicle.rows"
                                style="width:676px;height:65px"
                                :selectionMode="dataGridVehicle.selectionMode"
                                >
                            <GridColumnGroup :frozen="true" align="left" width="370">
                                <GridHeaderRow>
                                    <GridColumn field="vehicle_desc" title="Vehicle Id" :frozen="true" width="90"></GridColumn>
                                    <GridColumn field="driver_desc" title="Driver" :frozen="true" width="140"></GridColumn>
                                    <GridColumn field="co_driver_desc" title="Co Driver" :frozen="true" width="140"></GridColumn>
                                </GridHeaderRow>
                            </GridColumnGroup>
                            <GridColumnGroup>
                                <GridHeaderRow>
                                    <GridColumn field="transporter_desc" title="Transporter Id" width="130"></GridColumn>
                                    <GridColumn field="status_desc" title="Status" width="100"></GridColumn>
                                    <GridColumn field="type_desc" title="Type" width="100"></GridColumn>
                                    <GridColumn field="max_volume" title="Max Volume" width="100"></GridColumn>
                                    <GridColumn field="max_weight" title="Max Weight" width="100"></GridColumn>
                                </GridHeaderRow>
                            </GridColumnGroup>                            
                        </DataGrid>
                    </fieldset>
                    <fieldset class="c-fieldset">
                        <legend class="c-legend"><b>Details</b></legend>
                        <Tabs style="height:276px" @tabSelect="onTabSelected($event)">
                            <TabPanel :title="'Loading Summary'">
                                <table width="100%" cellspacing="1" cellpadding="5" border="0">
                                    <tbody>
                                        <tr>
                                            <td>Capacity</td>
                                            <td>
                                                <TextBox inputId="max_volume" name="max_volume" class="f-field-text" v-model="modelNotMapping.max_volume" :disabled="disableData">
                                                    <Addon>
                                                        <span class="text-inner-box">M3</span>
                                                    </Addon>
                                                </TextBox>
                                            </td>
                                            <td>Capacity</td>
                                            <td>
                                                <TextBox inputId="max_weight" name="max_weight" class="f-field-text" v-model="modelNotMapping.max_weight" :disabled="disableData">
                                                    <Addon>
                                                        <span class="text-inner-box">KG</span>
                                                    </Addon>
                                                </TextBox>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Load</td>
                                            <td>
                                                <NumberBox inputId="load_m3" name="load_m3" class="f-field-text" v-model="model.load_m3" :spinners="false" :disabled="disableData">
                                                    <Addon>
                                                        <span class="text-inner-box">M3</span>
                                                    </Addon>
                                                </NumberBox>
                                            </td>
                                            <td>Load</td>
                                            <td>
                                                <TextBox inputId="load_kg" name="load_kg" class="f-field-text" v-model="modelNotMapping.load_kg" :disabled="disableData">
                                                    <Addon>
                                                        <span class="text-inner-box">KG</span>
                                                    </Addon>
                                                </TextBox>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Utilization</td>
                                            <td>
                                                <TextBox inputId="utilization_m3" name="utilization_m3" class="f-field-text" v-model="modelNotMapping.utilization_m3" :disabled="disableData">
                                                    <Addon>
                                                        <span class="text-inner-box">%</span>
                                                    </Addon>
                                                </TextBox>
                                            </td>
                                            <td>Utilization</td>
                                            <td>
                                                <TextBox inputId="utilization_kg" name="utilization_kg" class="f-field-text" v-model="modelNotMapping.utilization_kg" :disabled="disableData">
                                                    <Addon>
                                                        <span class="text-inner-box">%</span>
                                                    </Addon>
                                                </TextBox>
                                            </td>
                                        </tr>
                                        <tr :hidden="disableClientMinWeight">
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>Min Weight</td>
                                            <td>
                                                <TextBox inputId="client_min_weight" name="client_min_weight" class="f-field-text" v-model="modelNotMapping.client_min_weight" :disabled="true">
                                                    <Addon>
                                                        <span class="text-inner-box">KG</span>
                                                    </Addon>
                                                </TextBox>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </TabPanel>
                            <TabPanel :title="'Drop Sequence'">
                                <div class="datagrid-toolbar" style="margin-bottom: 10px;">
                                    <LinkButton :plain="true" :iconCls="iconUtil.iconRefresh" @click="onRefresh()">{{ btnText.refresh }}</LinkButton>
                                    <span class="button-sep"></span>&nbsp;&nbsp;{{ search.text }} : 
                                    <SearchBox style="width:300px"
                                        :placeholder="search.placeholder" 
                                        v-model="dataGrid.search"
                                        @search="onSearch()">
                                        <Addon>
                                            <span v-if="dataGrid.search" class="textbox-icon icon-clear" title="Clear value" @click="dataGrid.search=null;onSearch();"></span>
                                        </Addon>
                                    </SearchBox>
                                </div>

                                <DataGrid
                                        ref="dataGrid"
                                        :columnResizing="true"
                                        :border="false"
                                        :loading="dataGrid.loading"                                    
                                        :data="dataGrid.rows"
                                        :pagination="true"
                                        :selectionMode="dataGrid.selectionMode"
                                        :total="dataGrid.total"
                                        :pageSize="dataGrid.pageSize"
                                        :pageNumber="dataGrid.pageNumber"
                                        :pageLayout="dataGrid.pageLayout"
                                        :footerData="dataGrid.footerData"
                                        :showFooter="true"
                                        @rowDblClick="onRowDblClick()"
                                        style="width:676px;height:203px"
                                        >
                                    <GridColumn field="customer_id" title="Point" :frozen="true" :sort="false"></GridColumn>
                                    <GridColumn field="customer_name" title="Name" :frozen="true"></GridColumn>
                                    <GridColumn field="customer_address" title="Address" width="100"></GridColumn>
                                    <GridColumn field="tm_state" title="Tm State" width="100"></GridColumn>
                                    <GridColumn field="ETA" title="ETA" width="100"></GridColumn>
                                    <GridColumn field="ATA" title="ATA" width="100"></GridColumn>
                                    <GridColumn field="EstDeparture" title="Est Departure" width="100"></GridColumn>
                                    <GridColumn field="ActualDeparture" title="Actual Departure" width="100"></GridColumn>
                                    <GridColumn field="LoadingTimes" title="Loading Times" width="100"></GridColumn>
                                    <GridColumn field="notes" title="Notes" width="100"></GridColumn>
                                </DataGrid>
                            </TabPanel>
                            <TabPanel :title="'Attachment'">
                                <div class="datagrid-toolbar" style="margin-bottom: 10px;">
                                    <LinkButton :plain="true" :iconCls="iconUtil.iconRefresh" @click="onRefreshFile()">{{ btnText.refresh }}</LinkButton>
                                    <span class="button-sep"></span>&nbsp;&nbsp;{{ search.text }} : 
                                    <SearchBox style="width:300px"
                                        :placeholder="search.placeholder" 
                                        v-model="dataGridFile.search"
                                        @search="onSearchFile()">
                                        <Addon>
                                            <span v-if="dataGridFile.search" class="textbox-icon icon-clear" title="Clear value" @click="dataGridFile.search=null;onSearchFile();"></span>
                                        </Addon>
                                    </SearchBox>
                                </div>

                                <DataGrid
                                        ref="dataGridFile"
                                        :columnResizing="true"
                                        :border="false"
                                        :loading="dataGridFile.loading"                                    
                                        :data="dataGridFile.rows"
                                        :pagination="true"
                                        :total="dataGridFile.total"
                                        :pageSize="dataGridFile.pageSize"
                                        :pageNumber="dataGridFile.pageNumber"
                                        :pageLayout="dataGridFile.pageLayout"
                                        :footerData="dataGridFile.footerData"
                                        :showFooter="true"
                                        style="width:676px;height:203px"
                                        >
                                    <GridColumn align="center" cellCss="datagrid-td-rownumber" width="30">
                                        <template slot="body" slot-scope="scope">
                                            {{scope.rowIndex + 1}}
                                        </template>
                                    </GridColumn>
                                    <GridColumn field="file_name" title="File Name" :sort="false"></GridColumn>
                                    <GridColumn field="act" title="Action" :sort="false">
                                        <template slot="body" slot-scope="scope">
                                            <LinkButton :plain="true" :iconCls="iconUtil.iconDownload" @click="onDownloadFile(scope.row)">{{ btnText.download }}</LinkButton>
                                        </template>
                                    </GridColumn>
                                </DataGrid>
                            </TabPanel>
                            <TabPanel :title="'Log'">
                                <table width="100%" cellspacing="1" cellpadding="5" border="0">
                                    <tbody>
                                        <tr>
                                            <td>
                                                Created By
                                            </td>
                                            <td>
                                                <TextBox inputId="created_by" name="created_by" class="f-field-text" :value="modelNotMapping.created_by" :disabled="true"></TextBox>
                                            </td>
                                            <td>
                                                Created Date
                                            </td>
                                            <td>
                                                <TextBox inputId="created_date" name="created_date" class="f-field-text" :value="modelNotMapping.created_date" :disabled="true"></TextBox>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Updated By
                                            </td>
                                            <td>
                                                <TextBox inputId="updated_by" name="updated_by" class="f-field-text" :value="modelNotMapping.updated_by" :disabled="true"></TextBox>
                                            </td>
                                            <td>
                                                Updated Date
                                            </td>
                                            <td>
                                                <TextBox inputId="updated_date" name="updated_date" class="f-field-text" :value="modelNotMapping.updated_date" :disabled="true"></TextBox>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </TabPanel>
                        </Tabs>
                    </fieldset>
                </Form>
            </div>
            <div class="dialog-button">
                <LinkButton :iconCls="iconUtil.iconCancel" @click="$refs.dlg.close()" style="width:89px">{{ btnText.close }}</LinkButton>
            </div>
        </Dialog>

        <NotificationComponent ref="notification"></NotificationComponent>
        <FormTruckingOrderComponent ref="formTruckingOrder" actionData="trucking_order" @onSaveDataVehicle="onLoadVehicle"></FormTruckingOrderComponent>
        <FormTimeWindowComponent ref="formTimeWindow" @onSaveData="onSaveDropSequence()"></FormTimeWindowComponent>
        <PrintManifestComponent ref="printManifest" :manifestId="manifestId"></PrintManifestComponent>
    </div>
</template>
<script>
    import Manifest from '@/models/Manifest';
    import InformationUtil from '@/utils/InformationUtil';
    import AccessUtil from '@/utils/AccessUtil';
    import TransportService from '@/services/TransportService';
    import OptionService from '@/services/OptionService';
    import ModelUtil from '@/utils/ModelUtil';
    import IconUtil from '@/utils/IconUtil';
    import GlobalUtil from '@/utils/GlobalUtil';

    import NotificationComponent from '../NotificationComponent.vue';
    import FormTruckingOrderComponent from './FormTruckingOrderComponent.vue';
    import FormTimeWindowComponent from './FormTimeWindowComponent.vue';
    import PrintManifestComponent from '../report/PrintManifestComponent.vue';

    export default {
        name : "FormManifestComponent",
        components : {
            NotificationComponent,
            FormTruckingOrderComponent,
            FormTimeWindowComponent,
            PrintManifestComponent
        },
        props : {
            actionData : {
                type : String,
                default : ''
            }
        },
        data(){
            return {
                title : InformationUtil.editText + " " + InformationUtil.manifest,
                titleMessage : InformationUtil.manifest,
                titleVehicle : InformationUtil.editText + " " + InformationUtil.vehicle,
                titleFile : InformationUtil.uploadFile,
                iconUtil : IconUtil,
                icon : IconUtil.iconEdit,

                actionDefault : "",

                model : Manifest,
                action : {
                    view : false,
                    add : false,
                    edit : false,
                    delete : false
                },
                menuAction :{
                    printManifest : false,
                    truckingOrder : false,                    
                },
                btnText :{
                    refresh : InformationUtil.refreshText,
                    add : InformationUtil.addText,
                    edit : InformationUtil.editText,
                    delete : InformationUtil.removeText,
                    save : InformationUtil.saveText,
                    cancel : InformationUtil.cancelText,
                    search : InformationUtil.searchText,
                    download : InformationUtil.downloadText,
                    close : InformationUtil.closeText,
                },
                search : {
                    text : InformationUtil.searching,
                    placeholder : InformationUtil.searchHolder
                },
                modelNotMapping : {
                    created_by : "",
                    updated_by : "",
                    created_date : "",
                    updated_date : "",
                    manifest_number : 0,
                    manifest_status : 0,
                    schedule_date_desc : null,
                    trip_no : null,
                    mileage : null,
                    trucking_order_number : null,
                    max_volume : null,
                    max_weight : null,
                    load_kg : null,
                    utilization_m3 : null,
                    utilization_kg : null,
                    invsal_hash_id : null,
                    invpch_hash_id : null,
                    client_min_weight : 0,
                    client_rate_type : 1,
                },
                
                disableData : true,
                disableBtnSave : false,
                disableManifestConfirm : false,
                disableManifestSelfBilling : false,
                disableClientMinWeight : true,

                dataGrid : {
                    loading: false,
                    rows : [],
                    total: 0,
                    start: 0,
                    pageNumber: 1,
                    pageSize: 5,
                    selectionMode : 'single',
                    pageLayout: ['first','prev','next','last','info'],

                    // filter
                    search : "",
                    sortField : "",
                    sortOrder : ""
                },

                dataGridVehicle : {
                    loading: false,
                    rows : [],
                    total: 0,
                    start: 0,
                    pageNumber: 1,
                    pageSize: 5,
                    selectionMode : 'single',

                    // filter
                    search : "",
                    sortField : "",
                    sortOrder : "",
                    id : null
                },

                dataGridFile : {
                    loading: false,
                    rows : [],
                    total: 0,
                    start: 0,
                    pageNumber: 1,
                    pageSize: 5,
                    selectionMode : 'single',
                    pageLayout: ['first','prev','next','last','info'],

                    // filter
                    search : "",
                    sortField : "",
                    sortOrder : ""
                },

                // validation
                rules: {
                    mode: 'required',
                    order_case: 'required'
                },
                errors: {},
                textError :{
                    mode : "",
                    order_case : ""
                },

                rulesVehicle: {
                    vehicle_id: 'required',
                    driver_id: 'required',
                    co_driver_id: 'required',
                    vehicle_type: 'required',
                    vehicle_status: 'required'
                },
                errorsVehicle: {},
                textErrorVehicle :{
                    vehicle_id: '',
                    driver_id: '',
                    co_driver_id: '',
                    vehicle_type: '',
                    vehicle_status: ''
                },

                // print 
                manifestId : 0,

                //combo box
                transportModes: [
                    { value :1, text :"LAND"},
                    { value :2, text :"AIR"},
                    { value :3, text :"SEA"},
                    { value :4, text :"RAILWAY"},
                ],
                orderCases: [
                    { value :0, text :"Regular"},
                    { value :1, text :"Urgent"},
                ],
                drivers : [],
                coDriver : [],
                vehicles : [],
                vehicleStatuses : [
                    {text: 'ON CALL', value: '1'},
                    {text: 'DEDICATED', value: '2'}
                ],
                vehicleTypes : [],

                // file data
                disableBtnSaveFile : false,
                file : '',
                fileName : '',
            }
            
        },
        methods : {
            onTabSelected(event){
                if(event.title == "Drop Sequence"){
                    this.onLoad();
                }
            },
            onClickMenu(row){
                if(row == "onViewTruckingOrder"){
                    this.$refs.formTruckingOrder.onPageShow(this.modelNotMapping.trucking_order_number);
                }
                
                if(row == "onPrint"){
                    this.onPrintManifest();
                }

                if(row == "onViewTracking"){
                    // open link 
                    window.open("https://tracking.erunix.id?id_manifest="+this.model.id, '_blank');
                }
            },
            getError(name) {
                return this.errors[name] && this.errors[name].length ? this.errors[name][0] : null;
            },
            getErrorVehicle(name) {
                return this.errorsVehicle[name] && this.errorsVehicle[name].length ? this.errorsVehicle[name][0] : null;
            },
            onPageShow(id){
                this.actionDefault = this.$props.actionData;
                this.action = AccessUtil.getAccessAction(this.$props.actionData);
                if(this.action.view && id != null && id != undefined){
                    TransportService.get('/manifest/' + id)
                        .then((response) => {
                            let dataResponse = response;
                            if(dataResponse.data !== "" && dataResponse.data !== null){

                                this.model = ModelUtil.resetModel(this.model);
                                this.model = ModelUtil.mapModel(this.model, dataResponse.data);

                                this.modelNotMapping = ModelUtil.resetModel(this.modelNotMapping);
                                this.modelNotMapping = ModelUtil.mapModel(this.modelNotMapping, dataResponse.data);

                                this.model.start = parseInt(this.model.start);
                                this.model.finish = parseInt(this.model.finish);
                                this.model.load_m3 = parseInt(this.model.load_m3);
                                this.model.client_min_weight = parseInt(this.model.client_min_weight);
                                this.modelNotMapping.manifest_number = String(this.modelNotMapping.manifest_number);

                                this.modelNotMapping.utilization_kg = String((this.modelNotMapping.load_kg / this.modelNotMapping.max_weight * 100).toFixed(2));
                                if(this.modelNotMapping.utilization_kg == "NaN"){
                                    this.modelNotMapping.utilization_kg = "0.00";
                                }

                                this.modelNotMapping.utilization_m3 = String((this.model.load_m3 / this.modelNotMapping.max_volume * 100).toFixed(2));
                                if(this.modelNotMapping.utilization_m3 == "NaN"){
                                    this.modelNotMapping.utilization_m3 = "0.00";
                                }

                                this.disableManifestConfirm = this.modelNotMapping.manifest_status > 0 ? true : false;
                                this.disableManifestSelfBilling = this.modelNotMapping.invpch_hash_id != null && this.modelNotMapping.invpch_hash_id != '' ? true : false;
                                this.disableClientMinWeight = this.modelNotMapping.client_rate_type == 2 ? false : true;

                                this.$refs.dlg.open();

                                this.onLoadVehicle();
                                this.onLoad();
                                this.onLoadFile();
                                this.manifestId = parseInt(this.modelNotMapping.manifest_number)
                            }
                        });
                }
            },
            onPageClose(){
                this.model = ModelUtil.resetModel(this.model);
                this.modelNotMapping = ModelUtil.resetModel(this.modelNotMapping);
                this.errors = {};
                this.textError = {};
                this.$refs.dlg.close();
            },  

            // data grid vehicle
            onLoadVehicle(){
                this.dataGridVehicle.id = this.model.id;
                return TransportService.post('/manifest/showVehicle', this.dataGridVehicle)
                    .then((response) => {
                        this.dataGridVehicle.loading = false;
                        if(response.data !== "" && response.data !== null){
                            if(response.code == 200){
                                let data = response.data
                                this.dataGridVehicle.total = data.total;
                                this.dataGridVehicle.rows = data.rows;
                            }else{
                                this.$messager.alert({
                                    title: "Error",
                                    icon: "error",
                                    msg: response.data.message
                                });
                            }
                        }
                    });
            },

            // data grid drop Sequence
            async onSaveDropSequence(){
                await this.onLoad();
                this.$emit('onSaveTimeWindow', this.model);
            },
            async onTabDropSequence(){
                await this.onLoad();
            },
            async onRefresh(){
                this.dataGrid.pageNumber = 1;
                this.$refs.dataGrid.unselectRow(this.$refs.dataGrid.selectedRows[0]);
                await this.onLoad();
                this.$refs.notification.success(InformationUtil.refresh);
            },
            async onSearch(){
                await this.onLoad();
            },
            onRowDblClick(){
                if(this.action.edit){
                    let data = this.$refs.dataGrid.selectedRows[0];
                    let param = {
                        manifestId : this.model.id,
                        pointId : data.point_id,
                        tmState : data.tm_state,
                        trafficMonitoringId : data.id
                    }
                    this.$refs.formTimeWindow.onPageShow(param);
                }
            },
            onLoad(){
                this.dataGrid.loading = true;
                let param = {
                    manifestId : this.model.id
                }
                return TransportService.getData('/timeWindow', this.dataGrid, param)
                    .then((response) => {
                        this.dataGrid.loading = false;
                        if(response.data !== "" && response.data !== null){
                            if(response.code == 200){
                                let data = response.data;
                                this.dataGrid.total = data.total;
                                this.dataGrid.rows = data.rows;
                            }else{
                                this.$messager.alert({
                                    title: "Error",
                                    icon: "error",
                                    msg: response.data.message
                                });
                            }
                        }
                    });
            },

            // manifest file
            onDownloadFile(row){
                if(row.id != null && row.id != ""){
                    let param = {
                        id : row.id
                    }
                    TransportService.downloadFile("/manifestFile/download", param)
                        .then((res) => {
                            if(res != null && res.type === 'application/pdf'){
                                let blob = new Blob([res], { type: 'application/pdf' })
                                let objectUrl = window.URL.createObjectURL(blob)
                                window.open(objectUrl)
                                window.URL.revokeObjectURL(blob);
                                this.$refs.notification.success(this.titleMessage + " " + InformationUtil.download);
                            } else {
                                let message = new Blob([res], { type: 'application/json' })
                                    message.text()
                                        .then(value => {
                                            let valMsg = JSON.parse(value);
                                            this.$messager.alert({
                                                title: "Error",
                                                icon: "error",
                                                msg: valMsg.message
                                            });
                                        })
                                        .catch(error => {
                                            console.log("Something went wrong" + error);
                                        });
                            }
                        }).catch((error) => {
                            this.$refs.notification.error(error);
                        });
                } else {
                    this.$messager.alert({
                        title: "Info",
                        icon: "info",
                        msg: InformationUtil.infoSelectFile
                    });
                }
            },
            async onRefreshFile(){
                await this.onLoadFile();
            },
            async onSearchFile(){
                await this.onLoadFile();
            },
            onLoadFile(){
                this.dataGridFile.loading = true;
                let param = {
                    manifestId : this.model.id
                }
                return TransportService.getData('/manifestFile', this.dataGridFile, param)
                    .then((response) => {
                        this.dataGridFile.loading = false;
                        if(response.data !== "" && response.data !== null){
                            if(response.code == 200){
                                let data = response.data;
                                this.dataGridFile.total = data.total;
                                this.dataGridFile.rows = data.rows;
                            }else{
                                this.$messager.alert({
                                    title: "Error",
                                    icon: "error",
                                    msg: response.data.message
                                });
                            }
                        }
                    });
            },

            // print manifest
            onPrintManifest(){
                this.manifestId = this.model.id;
                this.$refs.printManifest.printManifest();
            },

            async optionDriver(event){
                let search = event == null ? "" : event.filterValue;
                return await OptionService.getOption("/driver", search)
                    .then((response) => {
                        this.drivers = response.data;
                    }).catch((error) => {
                        this.$refs.notification.error(error);
                    });
            },
            async optionCoDriver(event){
                let search = event == null ? "" : event.filterValue;
                return await OptionService.getOption("/driver", search)
                    .then((response) => {
                        this.coDriver = response.data;
                    }).catch((error) => {
                        this.$refs.notification.error(error);
                    });
            },
            async optionVehicle(event){
                let search = event == null ? "" : event.filterValue;
                return await OptionService.getOption("/vehicle", search)
                    .then((response) => {
                        this.vehicles = response.data;
                    }).catch((error) => {
                        this.$refs.notification.error(error);
                    });
            },
            async optionVehicleType(event){
                let search = event == null ? "" : event.filterValue;
                return await OptionService.getOption("/vehicleType", search)
                    .then((response) => {
                        this.vehicleTypes = response.data;
                    }).catch((error) => {
                        this.$refs.notification.error(error);
                    });
            },
            onCallAllOption(){
                this.optionDriver();
                this.optionCoDriver();
                this.optionVehicle();
                this.optionVehicleType();
            },
        },
        created(){
            GlobalUtil.removeWindow();
        }
    }
</script>