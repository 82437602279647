<template>
    <div>
        <Panel :title="titleView" v-if="useDashboard"  style="margin:0px 1px; min-height: 480px; ">
            <div class="datagrid-toolbar">
                <LinkButton :plain="true" :iconCls="iconUtil.iconRefresh" @click="onRefresh()">{{ btnText.refresh }}</LinkButton>
                <span class="button-sep"></span>
                &nbsp;&nbsp;{{ filter.fromDate.text }}&nbsp;&nbsp;<DateBox v-model="filter.fromDate.value" format="dd/MM/yyyy" style="width: 175px"></DateBox>
                &nbsp;&nbsp;{{ filter.toDate.text }}  &nbsp;&nbsp;<DateBox v-model="filter.toDate.value" format="dd/MM/yyyy" style="width: 175px"></DateBox>
            </div>
            <Layout style="margin : 10px 0px;">
                <LayoutPanel>
                    <b-container fluid>
                        <b-row class="mt-2">
                            <b-col md="3" sm="12" >
                                <b-card class="card-congratulation-medal">
                                    <h5>Welcome, <br> {{ full_name }}! 🎉</h5>
                                    <b-card-text class="font-small-3">
                                        Have a good day!
                                    </b-card-text>
                                    <h3 class="mb-75 mt-4 ml-4">
                                        <b-link></b-link>
                                    </h3>
                                    <b-img
                                        :src="require('@/assets/images/dashboard-user.png')"
                                        class="congratulation-medal"
                                    />
                                </b-card>
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col md="4">
                                <b-card no-body class="card-browser-states">
                                    <b-card-header class="pb-0">
                                        <b-card-title>Trucking Order Status</b-card-title>
                                    </b-card-header>

                                    <b-card-body>
                                        <b-row>
                                            <b-col md="4" class="d-flex flex-column flex-wrap  mt-2" >
                                                <div class="text-left mt-2">
                                                    <h3 class="font-weight-bolder mb-0">
                                                        <b>{{ truckingOrderStatus.totalData }}</b>
                                                    </h3>
                                                    <h6>Total Trucking</h6>
                                                </div>
                                                <div class="mt-2">
                                                    <b-media no-body class="p-1" >
                                                        <b-media-aside class="mr-1">
                                                            <b-avatar rounded variant="light-danger" size="44" >
                                                                <feather-icon icon="InfoIcon" size="44"/>
                                                            </b-avatar>
                                                        </b-media-aside>
                                                        <b-media-body class="ml-2">
                                                            <h5 class="mb-0">
                                                                {{ truckingOrderStatus.open }}
                                                            </h5>
                                                            <big>Open</big>
                                                        </b-media-body>
                                                    </b-media>
                                                    <b-media no-body class="p-1">
                                                        <b-media-aside class="mr-1">
                                                            <b-avatar rounded variant="light-success" size="44">
                                                                <feather-icon icon="CheckSquareIcon" size="44"/>
                                                            </b-avatar>
                                                        </b-media-aside>
                                                        <b-media-body class="ml-2">
                                                            <h5 class="mb-0">
                                                                {{ truckingOrderStatus.close }}
                                                            </h5>
                                                            <big>Close</big>
                                                        </b-media-body>
                                                    </b-media>
                                                </div> 
                                            </b-col>

                                            <!-- chart -->
                                            <b-col md="8" class="d-flex justify-content-center">
                                                <vue-apex-charts
                                                    type="radialBar"
                                                    height="275"
                                                    :options="truckingOrderStatus.chartOptions"
                                                    :series="truckingOrderStatus.series"
                                                />
                                            </b-col>
                                            <!--/ chart -->
                                        </b-row>
                                    </b-card-body>
                                </b-card>
                            </b-col>

                            <b-col md="8">
                                <b-card no-body class="card-browser-states">
                                    <b-card-header>
                                        <div>
                                            <b-card-title>Traffic Monitoring stats</b-card-title>
                                        </div>
                                    </b-card-header>
                                    <b-card-body>
                                        <div>
                                            <b-row>
                                                <b-col md="3">
                                                    <b-media no-body>
                                                        <b-media-body class="ml-2">
                                                            <h5 class="mb-0">
                                                                {{ dataTrafficMonitoring.pickup }}
                                                            </h5>
                                                            <small>Pickup Order</small>
                                                        </b-media-body>
                                                    </b-media>
                                                </b-col>
                                                <b-col md="7">
                                                    <div class="align-items-center">
                                                        <ProgressBar :value="dataTrafficMonitoring.pickupPercent" class="progress-pickup"></ProgressBar>
                                                    </div>
                                                </b-col>
                                                <b-col md="2" class="p-0 m-0">
                                                    <span class="font-weight-bold text-body-heading mr-1">{{dataTrafficMonitoring.pickupPercent}} %</span>
                                                </b-col>
                                            </b-row>
                                            <b-row class="mt-2">
                                                <b-col md="3">
                                                    <b-media no-body>
                                                        <b-media-body class="ml-2">
                                                            <h5 class="mb-0">
                                                                {{ dataTrafficMonitoring.loading }}
                                                            </h5>
                                                            <small>Loading</small>
                                                        </b-media-body>
                                                    </b-media>
                                                </b-col>
                                                <b-col md="7">
                                                    <div class="align-items-center">
                                                        <ProgressBar :value="dataTrafficMonitoring.loadingPercent" class="progress-loading"></ProgressBar>
                                                    </div>
                                                </b-col>
                                                <b-col md="2" class="p-0 m-0">
                                                    <span class="font-weight-bold text-body-heading mr-1">{{dataTrafficMonitoring.loadingPercent}} %</span>
                                                </b-col>
                                            </b-row>

                                            <b-row class="mt-2">
                                                <b-col md="3">
                                                    <b-media no-body>
                                                        <b-media-body class="ml-2">
                                                            <h5 class="mb-0">
                                                                {{ dataTrafficMonitoring.finishLoading }}
                                                            </h5>
                                                            <small>Finish Loading</small>
                                                        </b-media-body>
                                                    </b-media>
                                                </b-col>
                                                <b-col md="7">
                                                    <div class="align-items-center">
                                                        <ProgressBar :value="dataTrafficMonitoring.finishLoadingPercent" class="progress-finish-loading"></ProgressBar>
                                                    </div>
                                                </b-col>
                                                <b-col md="2" class="p-0 m-0">
                                                    <span class="font-weight-bold text-body-heading mr-1">{{dataTrafficMonitoring.finishLoadingPercent}} %</span>
                                                </b-col>
                                            </b-row>

                                            <b-row class="mt-2">
                                                <b-col md="3">
                                                    <b-media no-body>
                                                        <b-media-body class="ml-2">
                                                            <h5 class="mb-0">
                                                                {{ dataTrafficMonitoring.arrived }}
                                                            </h5>
                                                            <small>Arrived</small>
                                                        </b-media-body>
                                                    </b-media>
                                                </b-col>
                                                <b-col md="7">
                                                    <div class="align-items-center">
                                                        <ProgressBar :value="dataTrafficMonitoring.arrivedPercent" class="progress-arrived"></ProgressBar>
                                                    </div>
                                                </b-col>
                                                <b-col md="2" class="p-0 m-0">
                                                    <span class="font-weight-bold text-body-heading mr-1">{{dataTrafficMonitoring.arrivedPercent}} %</span>
                                                </b-col>
                                            </b-row>

                                            <b-row class="mt-2">
                                                <b-col md="3">
                                                    <b-media no-body>
                                                        <b-media-body class="ml-2">
                                                            <h5 class="mb-0">
                                                                {{ dataTrafficMonitoring.unloading }}
                                                            </h5>
                                                            <small>Unloading</small>
                                                        </b-media-body>
                                                    </b-media>
                                                </b-col>
                                                <b-col md="7">
                                                    <div class="align-items-center">
                                                        <ProgressBar :value="dataTrafficMonitoring.unloadingPercent" class="progress-loading"></ProgressBar>
                                                    </div>
                                                </b-col>
                                                <b-col md="2" class="p-0 m-0">
                                                    <span class="font-weight-bold text-body-heading mr-1">{{dataTrafficMonitoring.unloadingPercent}} %</span>
                                                </b-col>
                                            </b-row>

                                            <b-row class="mt-2">
                                                <b-col md="3">
                                                    <b-media no-body>
                                                        <b-media-body class="ml-2">
                                                            <h5 class="mb-0">
                                                                {{ dataTrafficMonitoring.finishUnloading }}
                                                            </h5>
                                                            <small>Finish Unloading</small>
                                                        </b-media-body>
                                                    </b-media>
                                                </b-col>
                                                <b-col md="7">
                                                    <div class="align-items-center">
                                                        <ProgressBar :value="dataTrafficMonitoring.finishUnloadingPercent" class="progress-finish-loading"></ProgressBar>
                                                    </div>
                                                </b-col>
                                                <b-col md="2" class="p-0 m-0">
                                                    <span class="font-weight-bold text-body-heading mr-1">{{dataTrafficMonitoring.finishUnloadingPercent}} %</span>
                                                </b-col>
                                            </b-row>

                                        </div>
                                    </b-card-body>
                                </b-card>
                            </b-col>
                        </b-row>
                    </b-container>
                </LayoutPanel>
            </Layout>
        </Panel>    
    </div>
</template>

<script>
import GlobalUtil from '@/utils/GlobalUtil';
import IconUtil from '@/utils/IconUtil';
import InformationUtil from '@/utils/InformationUtil';

import AppService from '@/services/AppService';

// import chart
import ModelUtil from '@/utils/ModelUtil';
import VueApexCharts from 'vue-apexcharts'

export default {
    components: {
        VueApexCharts
    },
    name: 'DashboardView',
    data() {
        return {
            full_name : '',
            truckingOrderStatus: {
                totalData : 0,
                open : 0,
                close : 0,
                series: [0],
                chartOptions: {
                    plotOptions: {
                        radialBar: {
                            size: 150,
                            offsetY: 20,
                            startAngle: -150,
                            endAngle: 150,
                            hollow: {
                                size: '65%',
                            },
                            track: {
                                background: '#fff',
                                strokeWidth: '100%',
                            },
                            dataLabels: {
                                name: {
                                    offsetY: -5,
                                    color: '#5e5873',
                                    fontSize: '1rem',
                                },
                                value: {
                                    offsetY: 15,
                                    color: '#5e5873',
                                    fontSize: '1.714rem',
                                },
                            },
                        },
                    },
                    colors: ['#F97794'],
                    fill: {
                        type: 'gradient',
                        gradient: {
                        shade: 'dark',
                        type: 'horizontal',
                        shadeIntensity: 0.5,
                        gradientToColors: ['#0000ff'],
                        inverseColors: true,
                        opacityFrom: 1,
                        opacityTo: 1,
                        stops: [0, 100],
                        },
                    },
                    stroke: {
                        dashArray: 8,
                    },
                    labels: ['Completed'],
                },
            },

            titleView: 'Dashboard',
            urlAccess : 'dashboard',
            iconUtil : IconUtil,
            filter: {
                fromDate: {
                    text: 'From',
                    value: new Date()
                },
                toDate: {
                    text: 'To',
                    value: new Date()
                }
            },
            btnText: {
                refresh: InformationUtil.refreshText
            },
            useDashboard : true,
            dataTrafficMonitoring : {
                pickup : 0,
                loading : 0,
                finishLoading : 0,
                arrived : 0,
                unloading : 0,
                finishUnloading : 0,
                pickupPercent : 0.0,
                loadingPercent : 0.0,
                finishLoadingPercent : 0.0,
                arrivedPercent : 0.0,
                unloadingPercent : 0.0,
                finishUnloadingPercent : 0.0,
            },
        }
    },
    methods: {
        async onRefresh() {
            await this.onLoadData();
        },

        async getTruckingOrder(){
            AppService.post(this.urlAccess + '/truckingOrder', this.getParam())
            .then((response) => {
                if(response.code == 200){
                    this.dataChartTruckingOrder = response.data;
                    let dataOpen = 0;
                    let dataClose = 0;
                    let totalData = 0;
                    this.dataChartTruckingOrder.forEach(element => {
                        totalData += element.value;
                        if(element.type == "OPEN"){
                            dataOpen = element.value;
                        }

                        if(element.type == "CLOSE"){
                            dataClose = element.value;
                        }
                    });

                    this.truckingOrderStatus.totalData = totalData;
                    this.truckingOrderStatus.open = dataOpen;
                    this.truckingOrderStatus.close = dataClose;
                    let percent = 0;
                    if(dataClose > 0) {
                        percent = parseInt((dataClose / totalData) * 100);
                    }
                    this.truckingOrderStatus.series = [percent];
                }
            });
        },

        async getTrafficMonitoring(){
            AppService.post(this.urlAccess + '/trafficMonitoring', this.getParam())
            .then((response) => {
                if(response.code == 200){
                    this.dataTrafficMonitoring = ModelUtil.resetModel(this.dataTrafficMonitoring);
                    this.dataTrafficMonitoring = ModelUtil.mapModel(this.dataTrafficMonitoring, response.data);
                }
            });
        },

        async onLoadData(){
            await this.getTruckingOrder();
            await this.getTrafficMonitoring();
        },

        getParam(){
            let param = {
                startDate : this.$moment(this.filter.fromDate.value).format('YYYY-MM-DD'),
                endDate : this.$moment(this.filter.toDate.value).format('YYYY-MM-DD')
            }
            return param;
        },
    },
    async mounted() {
        await this.onLoadData();
        // get fullname
        this.full_name = this.$store.getters.user.name;
    },
    created(){
        GlobalUtil.removeWindow();
    }
}
</script>

<style scoped>
.layout-full{
    height: 100%;
}

.title{
    font-size: 12px;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
}

.content-chart{
    height: 90%;
    width: 100%;
}

.chart-data{
    height: 100%;
    width: 100%;
}

.content-traffic{
    padding: 10px 0px;
}
.item-traffic{
    margin-bottom: 15px;
}

.text-traffic{
    float: left;
    width: 100%;
    margin-bottom: 10px;
}

.title-traffic{
    width: 80%;
    float: left;
    font-size: 12px;
}

.percent-traffic{
    width: 20%;
    float: left;
    font-size: 12px;
    font-weight: bold;
    text-align: right;
}

/* Card */
.item-card{
    padding : 10px 20px;
}

.value-card {
    font-size: 20px;
    font-weight: bold;
}

.title-card{
    font-size: 12px;
}

.title-data{
    font-size: 12px;
    font-weight: bold;
    color: #333;
    /* padding : 10px; */
}



</style>

<style>

.progressbar {
    height: 7px;
    border-radius: 3px;
    margin-top: 5px;
}

.progress-pickup > .progressbar-value, .progress-pickup > .progressbar-value .progressbar-text {
    background-color: #2196f3 !important;
}

.progress-loading > .progressbar-value, .progress-loading > .progressbar-value .progressbar-text {
    background-color: #c77bd4 !important;
}

.progress-finish-loading > .progressbar-value, .progress-finish-loading > .progressbar-value .progressbar-text {
    background-color: #4caf50 !important;
}

/* units */
.item-units {
    float: left;
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, .2);
    padding: 10px 10px 15px 0px;
}

.title-units {
    font-size: 12px;
    padding: 0px 0px 0px 10px;
    width: 90%;
    float: left;
}

.value-units {
    width: 10%;
    float: left;
}

.content-units .item-units:last-child {
    border-bottom: none;
}

.total-shipment{
    background-color: #ffefa4;
    border-color: #ffd78a;
    color: #574802;
}

.total-pending-pod{
    background-color: #ffc9c9;
    border-color: #ffafbd;
    color: #ac1818;
}

.total-finish-pod{
    background-color: #bdedbc;
    border-color: #b4e8a8;
    color: #045702;
}

.card {
    border: 1px solid rgba(0,0,0,0.125) !important;
    margin-bottom: 2rem !important;
    box-shadow: none !important;
    transition: all .3s ease-in-out, background 0s, color 0s, border-color 0s !important;
}
</style>