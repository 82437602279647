<template>
    <div>
        <Panel :title="titleView"  style="margin:0px 1px; ">
            <Tabs style="height:540px">
                <TabPanel :title="'Personal Data'">
                    <fieldset class="c-fieldset">
                        <legend class="c-legend"><b>Data</b></legend>
                        <table width="50%" cellspacing="1" cellpadding="5" border="0">
                            <tbody>
                                <tr>
                                    <td>
                                        Email
                                    </td>
                                    <td>:</td>
                                    <td>
                                        <b>{{user.email}}</b>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="width:10%;">
                                        Code
                                    </td>
                                    <td style="width:2%;">:</td>
                                    <td>
                                        <b>{{user.transporte_id}}</b>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="width:10%;">
                                        Name
                                    </td>
                                    <td style="width:2%;">:</td>
                                    <td>
                                        <b>{{user.name}}</b>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Address
                                    </td>
                                    <td>:</td>
                                    <td>
                                        <b>{{user.address1}}</b><br/>
                                        <b>{{user.address2}}</b><br/>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        City
                                    </td>
                                    <td>:</td>
                                    <td>
                                        <b>{{user.city}}</b>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Postal Code
                                    </td>
                                    <td>:</td>
                                    <td>
                                        <b>{{user.postal_code}}</b>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Phone
                                    </td>
                                    <td>:</td>
                                    <td>
                                        <b>{{user.phone}}</b>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Fax
                                    </td>
                                    <td>:</td>
                                    <td>
                                        <b>{{user.fax}}</b>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </fieldset>
                </TabPanel>
                <TabPanel :title="'Reset Password'">
                    <fieldset class="c-fieldset">
                        <legend class="c-legend"><b>Password</b></legend>
                        <Form ref="form" :model="password" :rules="rules" @validate="errors=$event">
                            <table width="30%" cellspacing="1" cellpadding="5" border="0">
                                <tbody>
                                    <tr>
                                        <td class="bg_subtitle">
                                            Old Password <font color="red">*</font>
                                        </td>
                                        <td class="bg_grid2"> 
                                            <PasswordBox inputId="old_password" name="old_password" class="f-field-text" v-model="password.old_password" placeholder="Old Password"></PasswordBox>
                                            <div class="error">{{textError.old_password}}</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="bg_subtitle">
                                            New Password <font color="red">*</font>
                                        </td>
                                        <td class="bg_grid2"> 
                                            <PasswordBox inputId="new_password" name="new_password" class="f-field-text" v-model="password.new_password" placeholder="New Password"></PasswordBox>
                                            <div class="error">{{textError.new_password}}</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="bg_subtitle">
                                            Confirm Password <font color="red">*</font>
                                        </td>
                                        <td class="bg_grid2"> 
                                            <PasswordBox inputId="confirm_password" name="confirm_password" class="f-field-text" v-model="password.confirm_password" placeholder="Confirm Password"></PasswordBox>
                                            <div class="error">{{textError.confirm_password}}</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="2" class="bg_grid2" style="text-align: end;">
                                            <LinkButton :iconCls="iconUtil.iconSave" @click="onUpdatePassword()" :disabled="disableBtnSave" style="width:89px">{{ btnText.save }}</LinkButton>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </Form>
                    </fieldset>
                </TabPanel>
            </Tabs>
        </Panel>

        <NotificationComponent ref="notification"></NotificationComponent>
    </div>
</template>

<script>
import UserManagementService from '@/services/UserManagementService';

import InformationUtil from '@/utils/InformationUtil';
import IconUtil from '@/utils/IconUtil';
import ModelUtil from '@/utils/ModelUtil';
import GlobalUtil from '@/utils/GlobalUtil';

import NotificationComponent from '@/components/NotificationComponent.vue';

export default {
    name: "UserProfileView",
    components: {
        NotificationComponent
    },
    data() {
        return {
            titleView : InformationUtil.userProfile + "s",
            iconUtil : IconUtil,
            user: {
                id : 0,
                transporte_id : "",
                name : "",
                address1 : "",
                address2 : "",
                city : "",
                postal_code : "",
                phone : "",
                fax : "",
                email : ""
            },
            password : {
                old_password: "",
                new_password: "",
                confirm_password: "",
            },

            btnText :{
                refresh : InformationUtil.refreshText,
                add : InformationUtil.addText,
                edit : InformationUtil.editText,
                delete : InformationUtil.removeText,
                save : InformationUtil.saveText,
                cancel : InformationUtil.cancelText,
                search : InformationUtil.searchText
            },
            disableBtnSave : false,

            // validation
            rules: {
                old_password: 'required',
                new_password: 'required',
                confirm_password: 'required',
            },
            errors: {},
            textError :{
                old_password : "",
                password : "", 
                confirm_password : ""
            },
        };
    },
    methods: {
        getError(name) {
            return this.errors[name] && this.errors[name].length
            ? this.errors[name][0]
            : null;
        },
        onUpdatePassword(){
            ModelUtil.resetModel(this.textError);
            this.$refs.form.validate((valid) => {
                if(!valid){
                    if(this.password.new_password != this.password.confirm_password){
                        this.textError.confirm_password = "Password and Confirm Password not match";
                        return;
                    }
                    
                    this.disableBtnSave = true;
                    this.password.id = this.user.id;
                    UserManagementService.post('/user/updatePassword', this.password)
                        .then(response => {
                            this.disableBtnSave = false;
                            if(response.code == 200){
                                GlobalUtil.removeWindow();
                                this.$store.dispatch('logout')
                                this.$router.replace({ name: 'login' })
                            }else{
                                this.$messager.alert({
                                        title: "Error",
                                        icon: "error",
                                        msg: response.message
                                    });
                            }
                        }).catch(error => {
                            this.disableBtnSave = false;
                            this.$refs.notification.error(error);
                        });
                    
                } else {
                    for(let key in valid){
                        this.textError[key] = this.getError(key);
                    }
                }
            });
        }
    },
    mounted() {
        this.user.id = this.$store.getters.user.id;
        this.user.transporte_id = this.$store.getters.user.transporte_id;
        this.user.name = this.$store.getters.user.name;
        this.user.address1 = this.$store.getters.user.address1;
        this.user.address2 = this.$store.getters.user.address2;
        this.user.city = this.$store.getters.user.city;
        this.user.postal_code = this.$store.getters.user.postal_code;
        this.user.phone = this.$store.getters.user.phone;
        this.user.fax = this.$store.getters.user.fax;
        this.user.email = this.$store.getters.user.email;
    },
}
</script>