export default {
    state: { 
        menu: null,
        menuAccess: null,
    },
    mutations: { 
        set_menu (state, data) {
            state.menu = data
        }, 
        reset_menu (state) {
            state.menu = null
        },

        set_menu_access (state, data) {
            state.menuAccess = data
        },
        reset_menu_access (state) {
            state.menuAccess = null
        },
    },
    getters:{ 
        menu (state){
            return state.menu
        },
        menuAccess (state) {
            return state.menuAccess
        }
    },
    actions:{
        async getMenuAccess({ commit }){
            const menu = [
                {
                    "id": "trucking_order",
                    "text": "Trucking Order",
                    "iconCls": "",
                    "component": "TruckingOrderView"
                },
                {
                    "id": "traffic_monitoring",
                    "text": "Traffic Monitoring",
                    "iconCls": "",
                    "component": "TrafficMonitoringView"
                },
                {
                    "id": "user_profile",
                    "text": "User Profile",
                    "iconCls": "fa fa-user",
                    "component": "UserProfileView"
                },
                {
                    "id": "logout",
                    "text": "Logout",
                    "iconCls": "fa fa-sign-out",
                    "component": ""
                }
            ];

            const access = [
                {
                    "trucking_order": {
                        "is_view": 1,
                        "is_add": 0,
                        "is_edit": 1,
                        "is_delete": 0,
                        "is_print": 0
                    }
                },
                {
                    "traffic_monitoring": {
                        "is_view": 1,
                        "is_add": 0,
                        "is_edit": 1,
                        "is_delete": 0,
                        "is_print": 0
                    }
                },
                {
                    "user_profile": {
                        "is_view": 1,
                        "is_add": 0,
                        "is_edit": 0,
                        "is_delete": 0,
                        "is_print": 0
                    }
                },
                {
                    "logout": {
                        "is_view": 1,
                        "is_add": 0,
                        "is_edit": 0,
                        "is_delete": 0,
                        "is_print": 0
                    }
                }
            ];

            commit('set_menu', menu)
            commit('set_menu_access', access)
        }
    }
}